import React from "react"
import { Container } from "reactstrap"
import { Link } from "gatsby"

let SubFooter = ({ title }) => (
  <div className="bg-light" style={{ position: "relative", zIndex: 1200 }}>
    <Container className="pb-1">
      <Link
        to="/directory/michigan/top-web-design-companies/"
        className="text small"
        style={{ textDecoration: "none", color: "#11503a" }}
      >
        Top Web Design Firms
      </Link>
      <span style={{ marginLeft: ".25rem", marginRight: ".25rem" }}>•</span>
      <Link
        to="/directory/"
        className="text small"
        style={{ textDecoration: "none", color: "#11503a" }}
      >
        Directory
      </Link>
      <span style={{ marginLeft: ".25rem", marginRight: ".25rem" }}>•</span>
      <Link
        to="/directory/submit/"
        className="text small"
        style={{ textDecoration: "none", color: "#11503a" }}
      >
        Submit Company
      </Link>
    </Container>
    <Container className="pb-3">
      <span className="text small">
        Copyright © {new Date().getFullYear()}. Snappy Web Design. All rights
        reserved.
      </span>
    </Container>
  </div>
)

export default SubFooter
