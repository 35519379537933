import React, { useEffect } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import AppBar from "@material-ui/core/AppBar"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import { fade } from "@material-ui/core/styles/colorManipulator"
import Grid from "@material-ui/core/Grid"
import Toolbar from "@material-ui/core/Toolbar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import CssBaseline from "@material-ui/core/CssBaseline"
import Hidden from "@material-ui/core/Hidden"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import MenuIcon from "@material-ui/icons/Menu"
import IconButton from "@material-ui/core/IconButton"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Slide from "@material-ui/core/Slide"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import MailIcon from "@material-ui/icons/Mail"
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled"
import routes from "../data/navigation.json"

import logo from "../assets/images/logo.svg"

function HideOnScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger({
    // disableHysteresis: true,
    threshold: 50,
  })
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

function ElevationScroll(props) {
  const { children } = props

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
    color: trigger ? "inherit" : "default",
  })
}

const useStyles = makeStyles((theme) => ({
  bannerCtr: {
    zIndex: theme.zIndex.modal + 1,
    position: "relative",
    padding: ".75rem 10rem",
    backgroundColor: "#f5f5f5",
    [theme.breakpoints.down("md")]: {
      padding: ".75rem 2rem",
    },
  },
  logo: {
    marginLeft: "8rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0rem",
    },
  },

  darkDrawerIconCtr: {
    marginLeft: "auto",
  },
  lightTabContainer: {
    marginLeft: "auto",
    marginRight: "8rem",
    [theme.breakpoints.down("md")]: {
      marginRight: "0rem",
    },
    "&:hover": {
      "& a": {
        color: "rgba(0,0,0,.7)",
        textDecoration: "none",
      },
    },
  },
  lightTab: {
    fontFamily: "Open Sans",
    fontSize: ".875rem",
    fontWeight: 700,
    letterSpacing: 1,
    minWidth: 10,
    marginLeft: ".5rem",
    opacity: 1,
    color: fade(theme.palette.text.primary, 0.7),
    textTransform: "capitalize",
    "&:hover": {
      color: "rgba(0,0,0,.9)",
      textDecoration: "none",
    },
  },
  lightNav: {
    zIndex: theme.zIndex.modal + 1,
  },
  lightDrawer: {
    marginTop: "4rem",
    backgroundColor: theme.palette.background.paper,
    color: "#14151a",
    "&:hover": {
      "& a": {
        textDecoration: "none",
        color: "#14151a",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "3.5rem",
    },
  },
  drawerItem: {
    fontFamily: "raleway",
    textTransform: "capitalize",
    fontSize: "1rem",
    fontWeight: 600,
    opacity: 0.9,
    padding: ".25em 3em",
  },
  logoIconCtr: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  hoverFix: {
    "&:hover": {
      "& a": {
        color: "#14151a",
      },
    },
  },
  bannerText: {
    color: "#14151a",
    marginBottom: 0,
    "&:hover": {
      textDecoration: "none",
      color: "#14e3a1",
    },
  },
  drawerToolbar: {
    marginTop: "3rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0rem",
    },
  },
}))

export default function Navbar(props) {
  const classes = useStyles()
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [tabValue, setTabValue] = React.useState(0)
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  })

  const location = useLocation()
  useEffect(() => {
    let isCancelled = false
    const path = location.pathname

    if (!isCancelled) {
      if (path === "/") {setTabValue(0)} else
      if (path.includes("/portfolio")) {setTabValue(1)} else
      if (path.includes("/benefits")) {setTabValue(2)} else
      if (path.includes("/pricing")) {setTabValue(3)} else
      if (path.includes("/blog")) {setTabValue(4)} else
      if (path.includes("/about")) {setTabValue(5)} else
      if (path.includes("/contact")) {setTabValue(6)} else {
        setTabValue(false)
      }
    }

    return () => {
      isCancelled = true
    }
  }, [location])

  const drawer = (
    <>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{
          paper: classes.lightDrawer,
        }}
      >
        {!trigger && <div className={classes.drawerToolbar} />}
        <List>
          {routes.map((route, i) => {
            return (
              <ListItem
                divider
                key={`${route.name}${i}`}
                component={Link}
                to={route.url}
                button
                onClick={() => {
                  setOpenDrawer(false)
                }}
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  {route.name}
                </ListItemText>
              </ListItem>
            )
          })}
        </List>
      </SwipeableDrawer>
      <IconButton
        aria-label="navigation menu"
        className={classes.darkDrawerIconCtr}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon color="inherit" />
      </IconButton>
    </>
  )

  const tabs = (
    <>
      <Tabs
        TabIndicatorProps={{ style: { background: "#26a27b" } }}
        value={tabValue}
        className={classes.lightTabContainer}
      >
        {routes.map((route, i) => {
          return (
            <Tab
              component={Link}
              to={route.url}
              className={classes.lightTab}
              key={`${route.name}${i}`}
              label={route.name}
              aria-label={route.name}
              disableRipple
            />
          )
        })}
      </Tabs>
    </>
  )

  return (
    <>
      <CssBaseline />
      <Hidden xsDown>
        <HideOnScroll {...props}>
          <Grid container item xs={12} className={classes.bannerCtr}>
            <Grid
              container
              item
              xs={6}
              justify="flex-start"
              className={classes.hoverFix}
            >
              <Link
                to="/pricing/"
                title="Free Web Design Estimate"
                className={classes.bannerText}
              >
                Get a Free Estimate <ArrowForwardIcon />
              </Link>
            </Grid>
            <Grid
              container
              item
              xs={6}
              justify="flex-end"
              className={classes.hoverFix}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:start@snappywebdesign.net"
                title="Free Web Design Estimate"
                className={classes.bannerText}
              >
                <MailIcon fontSize="small" /> start@snappywebdesign.net
              </a>
              <a
                style={{ marginLeft: "1rem" }}
                target="_blank"
                rel="noreferrer"
                href="tel:7344899055"
                title="Call Snappy Web Design"
                className={classes.bannerText}
              >
                <PhoneEnabledIcon fontSize="small" /> 734-489-9055
              </a>
            </Grid>
          </Grid>
        </HideOnScroll>
      </Hidden>
      <ElevationScroll>
        <AppBar position="sticky" className={classes.lightNav}>
          <Toolbar>
            <IconButton
              aria-label="Back to Home Page"
              component={Link}
              to="/"
              disableRipple
              className={classes.logoIconCtr}
            >
              <img
                src={logo}
                className={classes.logo}
                style={{ textTransform: "none" }}
                alt="Snappy Web Design Logo"
                height="30px"
              />
            </IconButton>
            {matchesSM ? drawer : tabs}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  )
}
