
import React, { useEffect } from "react"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
// import Cookies from "universal-cookie"
import LightNavbar from "./lightNavBar"
import Navbar from "./Navbar"
import Footer from "./footer"
import SubFooter from "./subFooter"
import "../assets/stylesheets/layout.scss"
import { ThemeProvider } from "styled-components"
import theme from "../assets/stylesheets/theme"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import Divider from "@material-ui/core/Divider"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Container from "@material-ui/core/Container"
import Checkbox from "@material-ui/core/Checkbox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Popover from "@material-ui/core/Popover"
// const cookies = new Cookies()
const dark = "#1c8460"
const primary = "#26a27b"

const useStyles = makeStyles((theme) => ({
  modalH2: {
    fontFamily: "raleway, roboto, sans-serif",
    fontSize: "4rem",
    fontWeight: 600,
    color: "#fff",
  },
  modalBody: {},
  icoBtn: {
    position: "absolute",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  dialog: {
    backgroundColor: dark,
    padding: "2rem",
    color: "#fff",
  },
  textField: {
    backgroundColor: "#fff",
    color: "#000",
    "& label.Mui-focused": {
      color: "rgba(0,0,0,.8)",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: primary,
    },
  },
  popover: {
    padding: "1rem",
    backgroundColor: primary,
  },
  closeRow: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "-2rem",
      marginLeft: "2rem",
      marginBottom: "2rem",
    },
  },
}))

const CustomCheckbox = withStyles({
  root: {
    color: "#fff",
    "&$checked": {
      color: "#fff",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const Layout = ({ children, grey = null, dark = false }) => {
  const ref = React.useRef()
  const [onHomePage, setOnHomePage] = React.useState(false)
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [state, setState] = React.useState({
    checkedSeo: false,
    checkedQuote: true,
  })
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openX = Boolean(anchorEl)
  const id = openX ? "simple-popover" : undefined

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          phone
          email
          address
        }
      }
    }
  `)
  let { title,  } = data.site.siteMetadata

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      // if (count !== 1) {
      //   setCount(1)
      //   handleClick()
      //   setTimeout(() => {
      //     setCount(null)
      //   }, 7500)
      //   return
      // } else {
      setOpen(false)
      // setCount(null)
      setAnchorEl(null)
      // }
    }
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }


  const location = useLocation()
  useEffect(() => {
    let isCancelled = false

    const path = location.pathname
    if (path === "/") {
      setOnHomePage(true)
    } else {
      setOnHomePage(false)
    }

    // if (cookies.get("freebieOff") === "true" || path.includes("/blog")) {
    //   return
    // } else {
    //   if (!isCancelled) {
    //     setTimeout(() => {
    //       setOpen(true)
    //       cookies.set("freebieOff", true, { path: "/", maxAge: 10800 })
    //     }, 15000)
    //   }
    // }
    return () => {
      isCancelled = true
    }
  }, [location])

  return (
    <ThemeProvider theme={theme}>
      {onHomePage ? <Navbar /> : <LightNavbar />}
      <main style={{ backgroundColor: grey ? "#f5f5f5" : null }}>
        {children}
      </main>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="freebie-popup-title"
        classes={{
          paper: classes.dialog,
        }}
      >
        <Grid
          item
          container
          direction="row-reverse"
          className={classes.closeRow}
        >
          <IconButton
            ref={ref}
            disableRipple
            className={classes.icoBtn}
            onClick={() => {
              setOpen(false)
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <form name="popup" method="POST" netlify data-netlify="true">
          <input type="hidden" name="form-name" value="popup" />
          <DialogContent>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  className={classes.modalH2}
                  align="center"
                >
                  WAIT!
                </Typography>
              </Grid>
              <DialogContentText color="inherit" style={{ fontSize: "1.5rem" }}>
                Don't go without claiming your freebies!
              </DialogContentText>
              <Divider
                style={{
                  backgroundColor: "rgba(255,255,255,0.8)",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              />
              <Container maxWidth="xs">
                <Grid item xs={12}>
                  <Typography
                    paragraph
                    align="center"
                    color="inherit"
                    style={{ fontSize: "1.25rem" }}
                  >
                    I want a...
                  </Typography>

                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={state.checkedSeo}
                        onChange={handleChange}
                        name="checkedSeo"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                      />
                    }
                    label={
                      <span style={{ fontWeight: 500, fontSize: "1.1rem" }}>
                        Free Website SEO Health Report
                      </span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={state.checkedQuote}
                        onChange={handleChange}
                        name="checkedQuote"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                      />
                    }
                    label={
                      <span style={{ fontWeight: 500, fontSize: "1.1rem" }}>
                        Free Custom Website Quote
                      </span>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    // autoFocus
                    variant="filled"
                    margin="dense"
                    id="popupsite"
                    name="popupsite"
                    label="Site url"
                    type="url"
                    required
                    fullWidth
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="filled"
                    margin="dense"
                    id="popupemail"
                    name="popupemail"
                    label="Email address"
                    type="email"
                    required
                    fullWidth
                    style={{ marginTop: "1rem" }}
                    className={classes.textField}
                  />
                </Grid>
              </Container>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Container maxWidth="xs">
              <Grid container justify="center">
                <Button
                  as="button"
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{ padding: ".65rem", borderRadius: 0 }}
                  disableElevation
                >
                  Claim freebie(s)!
                </Button>
              </Grid>
            </Container>
          </DialogActions>
        </form>
      </Dialog>
      <Popover
        id={id}
        open={openX}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.popover,
        }}
      >
        <Typography
          align="center"
          style={{ color: "#fff", fontSize: "1.25rem", fontWeight: 500 }}
        >
          Last chance to collect your free personalized reports!
          <br /> Click outside twice to return to website.
        </Typography>
      </Popover>
      <Footer />
      <SubFooter title={title} />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
