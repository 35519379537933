import React from "react"
import { Container, Row, Col } from "reactstrap"
import slugify from "slugify"
import { FaFacebookSquare, FaTwitter, FaLinkedin } from "react-icons/fa"
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Link from "./link"
import styled from "styled-components"
import footerItems from "../data/footer.json"
import Typography from "@material-ui/core/Typography"

const FooterStyling = styled.footer`
  z-index: 1200;
  position: relative;
  padding: 3rem 0;
  background: #f8f9fa;
  a,
  a:hover {
    color: inherit;
  }
  ul {
    color: rgba(0, 0, 0, 0.7);
    -webkit-padding-start: 0;
    padding: 0;
    & > li {
      list-style: none;
      padding: 0.25rem 0;
      font-size: 1.125rem;

      a,
      a:hover {
        color: inherit;
      }
    }
  }
`

let SocialLink = ({ Icon, Destination, alt }) => (
  <Link to={Destination} title={alt} className="mr-2">
    <Icon size={30} alt={alt} />
  </Link>
)

let FooterLink = ({ to, children }) => (
  <li>
    <Link to={to}>{children}</Link>
  </li>
)

let Footer = () => {
  const {
    allContentfulCountyServiceArea: { nodes: counties },
    allContentfulCityServiceArea: { nodes: cities },
    allContentfulStateServiceArea: { nodes: states },
    // site: { siteMetadata: siteUrl },
  } = useStaticQuery(allServiceLocationsQuery)
  let keyCount = 50
  const getKey = () => {
    return keyCount++
  }
  return (
    <FooterStyling>
      <Container>
        <Row>
          {footerItems.map((item) => {
            return (
              <Col key={getKey()} xs={6} md={3} className="py-2">
                {item.name === "Home" ? (
                  <h5>
                    <Link to={item.url}>{item.name}</Link>
                  </h5>
                ) : (
                  <h5>{item.name}</h5>
                )}
                <ul>
                  {item.dropdownItems.map((dropdownItem) => (
                    <FooterLink key={getKey()} to={dropdownItem.url}>
                      {dropdownItem.name}
                    </FooterLink>
                  ))}
                </ul>
              </Col>
            )
          })}
          <Col xs={6} md={3}>
            <h5>
              <Link to={"/contact/"}>Contact Us</Link>
            </h5>
            <SocialLink
              Destination="https://www.facebook.com/Snappy-Web-Design-104414755169482"
              Icon={FaFacebookSquare}
              alt="Snappy Web Design on Facebook"
            />
            <SocialLink
              Destination="https://twitter.com/snappy_web"
              Icon={FaTwitter}
              alt="Snappy Web Design on Twitter"
            />
            <SocialLink
              Destination="https://www.linkedin.com/in/snappywebdesign/"
              Icon={FaLinkedin}
              alt="Snappy Web Design on LinkedIn"
            />
          </Col>
        </Row>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#494c4b",
            marginBottom: "1.5em",
            marginTop: "1em",
          }}
        >
          <Grid container justify="center">
            <Typography
              variant="h6"
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
              component={GatsbyLink}
              to="/locations/"
              title="Snappy Locations"
            >
              Service Areas
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid
            container
            direction="row"
            // justify="center"
            alignItems="center"
            spacing={1}
          >
            {cities.map((city, index) => {
              const slug = slugify(city.url, { lower: true })
              return (
                <Grid item xs={6} sm={4} md={3} key={city.id}>
                  <Grid container justify="center">
                    <GatsbyLink
                      style={{
                        color: "rgba(0, 0, 0, 0.7)",
                        // marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      to={`/locations/${slug}/`}
                      title={`${city.city} Web Design`}
                    >
                      {city.city}
                    </GatsbyLink>
                  </Grid>
                </Grid>
              )
            })}
            {counties.map((county) => {
              const slug = slugify(county.url, { lower: true })
              return (
                <Grid item xs={6} sm={4} md={3} key={county.id}>
                  <Grid container>
                    <GatsbyLink
                      style={{ color: "rgba(0,0,0,.75)" }}
                      to={`/locations/${slug}/`}
                      title={`${county.county} Web Design`}
                    >
                      {county.county}
                    </GatsbyLink>
                  </Grid>
                </Grid>
              )
            })}
            {states.map((state) => {
              const slug = slugify(state.url, { lower: true })
              return (
                <Grid item xs={6} sm={4} md={3} key={state.id}>
                  <Grid container>
                    <GatsbyLink
                      style={{ color: "rgba(0,0,0,.75)" }}
                      to={`/locations/${slug}/`}
                      title={`${state.state} Web Design`}
                    >
                      {state.state}
                    </GatsbyLink>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Container>
    </FooterStyling>
  )
}

export const allServiceLocationsQuery = graphql`
  {
    allContentfulCityServiceArea(
      sort: { fields: city, order: ASC }
      filter: { fullLocation: { regex: "/Michigan/" } }
    ) {
      nodes {
        id
        url
        city
        fullLocation
        zipCodes
      }
      totalCount
    }
    allContentfulCountyServiceArea(
      sort: { fields: county, order: ASC }
      filter: { fullLocation: { regex: "/Michigan/" } }
    ) {
      nodes {
        id
        url
        county
        fullLocation
        zipCodes
      }
      totalCount
    }
    allContentfulStateServiceArea(
      filter: { fullLocation: { regex: "/Michigan/" } }
    ) {
      nodes {
        id
        url
        state
        fullLocation
        zipCodes
      }
      totalCount
    }
  }
`

export default Footer
